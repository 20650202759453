import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import Strip from '../Strip/Strip'

const Service = () => {
    return (
        <Strip
            id="tst-service"
            className="strip mt-5 mb-5"
            containerType="container"
        >
            <Row className="mx-0 align-items-center pt-5 pb-5">
                <Col xs={12} sm={12} md={12} lg={10} xl={10}>
                    <h4 className="section-title title-grey">Services</h4>
                    <h5 className="h5-small section-title">Technology Transfer</h5>
                    <p className="mb-0 section-sub-title">
                        RX Ventures develops technology with a vision to provide scale and accessibility with minimal infrastructure. We partner with companies globally to achieve this goal. Current projects include development of
                   </p>
                    <div className="pt-5">
                        <p className="mb-0 section-sub-title pb-3">
                            Reverse Transcription Loop mediated IsoThermal Amplification (RT-LAMP) for rapid diagnosis of COVID19 testing.
                       </p>
                        <p className="mb-0 section-sub-title">
                          Genomics in early diagnosis of chronic health conditions.
                       </p>
                    </div>
                </Col>
            </Row>
        </Strip>
    )
}


export default Service