import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
//import { GatsbyImage } from "gatsby-plugin-image"
// Note: You can change "images" to whatever you'd like.
//https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-image/src/index.js#L227

const ImageContainer = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          images: allFile {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const image = data.images.edges.find((n) => {
          return n.node.relativePath.includes(props.fileName);
        });
        if (!image) {
          return null;
        }

        //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
        return (
          <Img
            alt={props.alt}
            fluid={image.node.childImageSharp.fluid}
            style={{ position: "initial" }}
            className={`${props.classNames} ${props.imgClassNames}`}
            placeholderClassName="img_placeholder"
            placeholderStyle={{
              position: "absolute",
              width: "auto",
              height: "auto",
              opacity: 0,
            }}
            imgStyle={{ position: "relative" }}
            
          />
        );
      }}
    />
  );
};

ImageContainer.propTypes = {
  classNames: PropTypes.string,
};

ImageContainer.defaultProps = {
  classNames: `img__container`,
};


export default ImageContainer;
