import * as React from "react"
import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from 'react-scroll-section';
import Layout from "../components/layout"
import Seo from "../components/seo"
import RenderPage from "../components/RenderPage/RenderPage"
import AboutUs from "../components/AboutUs/AboutUs"
import Service from "../components/Service/Service"
import ContactUs from "../components/ContactUs/ContactUs"


const StaticMenu = () => {
  const aboutSection = useScrollSection('about');
  const serviceSection = useScrollSection('service');
  const contactSection = useScrollSection('contact-us');

  return (
    <div className="header-container">
      <div className="d-flex align-items-center justify-content-between container">
      <div>
        <h4 className="mb-0 title-container fw-800">RX Ventures</h4>
      </div>
      <div>
        <ul className="d-flex align-items-center menu-container">
          <li className={`menu-item ${aboutSection.selected ? 'active' : ''}`} onClick={aboutSection.onClick} selected={aboutSection.selected}>
            About Us
      </li>
          <li className={`menu-item ${serviceSection.selected ? 'active' : ''}`} onClick={serviceSection.onClick} selected={serviceSection.selected}>
            Service
      </li>
          <li className={`menu-item ${contactSection.selected ? 'active' : ''}`} onClick={contactSection.onClick} selected={contactSection.selected}>
            Contact Us
      </li>
        </ul>
      </div>
      </div>
    </div>
  );
};

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <ScrollingProvider>
        <RenderPage
          id="contact"
          className="render-page render--page px-0"
          containerType="container-fluid"
        >
          <StaticMenu />
          <Section id="about" className="pt-2"><AboutUs /></Section>
          <Section id="service"><Service /></Section>
          <Section id="contact-us"><ContactUs /></Section>
        </RenderPage>
      </ScrollingProvider>
    </Layout>
  )
}


export default IndexPage
