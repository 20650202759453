import React from 'react'
import { Row, Col } from 'reactstrap'
import Strip from '../Strip/Strip'
import ImageContainer from '../ImageContainer/ImageContainer'
import applicationConstants from '../../applicationContants/applicationContants';

const AboutUs = () => {
    return (
        <div>
            <Strip
                id="tst-About"
                className="strip px-0"
                containerType="container"
            >
                <Row className="mx-0 align-items-center pt-5 pb-5">
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Row className="mx-0">
                            <Col xs={12} sm={12} md={12} lg={10} xl={10} className="px-0">
                                <h4 className="section-title title-grey">About Us</h4>
                                <p className="mb-0 section-sub-title">
                                    RX Ventures LLC, is an early stage biotech company involved in the Research and Development of Genomics in the Early diagnosis of Chronic Health Conditions. We develop diagnostic technologies with a wide array of applications with a focus on developing low cost diagnostic tools that can be implemented in large scale with minimal infrastructure.                            </p>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <Row className="mx-0 align-items-center justify-content-center">
                            <Col xs={12} sm={12} md={12} lg={10} xl={10}>
                                <ImageContainer fileName={applicationConstants.homeImg} alt=""
                                    imgClassNames={"home-img-contianer"} />
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </Strip>
            <Strip
                id="tst-About"
                className="strip px-0 bg-blue pt-3 pb-3"
                containerType="container-fluid"
            >
                <div className="container">
                    <Row className="mx-0 align-items-center pt-5 pb-5">
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <div>
                                <div>
                                    <p className="mb-0 section-sub-title title-white pb-2 fw-700">
                                        Our Mission
                             </p>
                                    <p className="mb-0 section-sub-title title-white">
                                        Provide low cost easy to use diagnostic tools in the field of medicine
                           </p>
                                </div>
                                <div className="pt-5">
                                    <p className="mb-0 section-sub-title title-white pb-2 fw-700">
                                        Our Vision
                              </p>
                                    <p className="mb-0 section-sub-title title-white">
                                        Democratize access to cutting edge diagnostic laboratory tools
                             </p>
                                </div>
                            </div>

                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Row className="mx-0 align-items-center justify-content-center">
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ImageContainer fileName={applicationConstants.dotImg} alt=""
                                        imgClassNames={"dot-container"} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Strip>
        </div>
    )
}


export default AboutUs