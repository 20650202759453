import React, { Component } from 'react'
import { Col, Row } from 'reactstrap'
import Strip from '../Strip/Strip'

const ContactUs = () => {
    return (
        <Strip
            id="tst-contactus"
            className="strip px-0 bg-white-smoke pt-5 pb-5"
            containerType="container-fluid"
        >
            <div className="container">
                <Row className="mx-0 align-items-center pt-3 pb-3">
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <h4 className="section-title title-grey">Contact Us</h4>
                    </Col>
                    <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                        <h6 className="h6-small mb-0 section-title pb-3">
                            Write to us
                   </h6>
                        <p className="mb-0 section-sub-title">
                            RX VENTURES LLC
                    </p>
                        <p className="mb-0 section-sub-title">
                            222, Pacific Coast Highway, 10th Floor, El Segundo CA 90245
                    </p>
                        <p className="mb-0 section-sub-title">
                            Email: info@rxventuresusa.com
                    </p>
                        <p className="mb-0 section-sub-title">
                            Ph: 1-866-726-6257
                    </p>
                    </Col>
                </Row>
            </div>
        </Strip>
    )
}


export default ContactUs